.top_stats {
  @apply flex flex-col items-center pt-5;
}

.top_stats_head {
  @apply flex items-center gap-8;
}

.top_stats_title {
  @apply airfactory-r-reg-40;
}

.top_stats_body {
  @apply mt-6 flex items-center gap-9 md:flex-nowrap flex-wrap;
}

.stats_card {
  @apply border border-solid border-c_090AC1 rounded;
  @apply bg-c_010127 flex items-start justify-center;
  @apply gap-5 py-4;
  min-width: 300px;
}

.stats_card_icon {
}

.stats_card_text {
  @apply flex flex-col items-start;
}
.stats_card_maintext {
  @apply brandon-g-reg-22 text-white;
}

.stats_card_subtext {
  @apply brandon-g-reg-17 text-c_FFDE00;
}
