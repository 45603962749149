.wallet_dialog_network_list li {
  @apply border-b border-solid border-c_333333;
}

.wallet_dialog_network_list li {
  @apply px-6;
}

.wallet_dialog_network_list li:hover {
  @apply bg-c_090AC14D;
}

.wallet_dialog_network_list button {
  @apply flex items-center py-5 w-full relative;
}

.wallet_dialog_network_list button img {
  width: 16px;
}

.wallet_dialog_display_name {
  @apply proxima-n-semibold-18 text-c_818181 ml-6;
}

.wallet_dialog_chainStatus {
  @apply absolute right-0 text-black bg-black rounded-full;
  content: "";
  width: 16px;
  height: 16px;
}

.wallet_dialog_activeChain {
  @apply text-c_FFDE00 bg-c_FFDE00;
}

.title {
  @apply flex items-center gap-4;
  @apply text-white brandon-g-bold-20;
}

.sub {
  @apply proxima-n-reg-12 text-c_707070;
}

.continue_button {
  @apply flex items-center justify-center w-full pb-8;
}

.continue_button_button {
  @apply flex gap-4 px-8 py-2 justify-between;
}

.continue_button_button svg {
  transition: all 0.2s linear 0s;
}

.continue_button_button:hover svg {
  @apply fill-current text-c_FFDE00;
}

.continue_button_button p {
  @apply brandon-g-med-17;
}

.title_p {
  @apply flex items-center justify-center gap-4 pb-3 w-full;
}

.title_p_sub {
  @apply text-center leading-4;
  line-height: 16px !important;
}

.back_button:hover svg path {
  @apply fill-current text-c_FFDE00;
}

.type_img_width {
  @apply w-6 !important;
}

.configure_lock_container {
}

.configure_lock_section {
  @apply p-6 flex justify-between gap-4;
  @apply border-b border-c_333333;
}

.configure_lock_left {
  @apply w-1/2;
}

.configure_lock_title {
  @apply text-c_818181 text-lg pb-3;
}

.configure_lock_balance {
  @apply text-c_FFDE00 text-lg pb-3 text-right;
}

.configure_lock_icons {
  @apply flex py-2 items-center gap-3 justify-end;
}

.configure_lock_icons img {
  @apply w-auto h-6;
}

.configure_lock_icons button {
  @apply flex items-center justify-center;
  @apply text-c_090AC1 uppercase bg-c_B9AA46;
  @apply px-4 py-2 rounded text-xs;
}

.configure_lock_icons button:hover {
  @apply bg-c_FFDE00;
}

.configure_lock_input {
  padding-block: unset !important;
  padding-inline: unset !important;
}

.configure_lock_right {
  @apply w-1/2;
}

.configure_lock_symbol {
  @apply text-c_B9AA46 text-base overflow-ellipsis;
  @apply overflow-hidden whitespace-nowrap text-right;
  width: 56px;
}

.token_validity {
  @apply flex items-center gap-2 justify-center;
}

.token_validity img {
  width: 18px;
}
.configure_lock_input_disabled div input {
  border: 0 !important;
  font-size: 0.75rem;
  @apply text-c_7A7A7A !important;
  padding-left: 3rem !important;
}

.configure_lock_addy {
  @apply flex flex-col w-full;
}

.configure_lock_addy h3 {
  @apply flex items-center justify-between;
}

.configure_lock_addy h3 span img {
  @apply w-4;
}
.configure_lock_addy h3 span {
  @apply text-xs flex items-center gap-2;
}

.configure_lock_form_icons {
  @apply relative;
}

.configure_lock_form_icons img {
  @apply w-auto h-6;
  @apply absolute left-0 top-1/4 z-10 ml-4;
}

.continue_approve_confirm {
  @apply flex items-center justify-center gap-5;
}

.approve_confirm {
  @apply w-2/5 text-center flex items-center justify-center;
}
