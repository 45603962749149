.menu_container {
  @apply relative inline-block text-left;
}

.menu_chevron {
  @apply w-5 h-5 ml-2 -mr-1 text-white;
  @apply hover:text-white;
}

.menu_button {
  @apply flex items-center gap-2 w-full px-4 py-2;
  @apply text-sm text-white bg-gray-500;
  @apply rounded bg-opacity-50;
}

.menu_button svg {
  @apply w-5;
}

.menu_items {
  @apply absolute right-0 mt-2 origin-top-right bg-white;
  @apply divide-y divide-gray-100 rounded-md shadow-lg ring-1;
  @apply ring-black ring-opacity-5 focus:outline-none;
}
.chain_item {
  @apply group flex rounded-md items-center w-full px-4 py-2 text-sm;
  @apply flex items-center gap-4 text-black;
}

.chain_item:hover {
  @apply text-black bg-gray-300 bg-opacity-80;
}

.chain_item[disabled] {
  @apply /*text-koro_dark_purple*/ text-opacity-25 /*bg-koro_grey*/;
  @apply cursor-not-allowed;
}

.menu_notAuth {
  @apply bg-opacity-30;
}
