@tailwind base;
@tailwind components;
@tailwind utilities;


@media screen and (min-width:769px) {
    .desktop-locked{
        display: inline-block;
    }
    .mobile-locked{
        display: none !important;
    }
}

@media screen and (max-width:768px) {
    .table__data {
        width: 100% !important;
        overflow-x: scroll;
    }

    .table__tr{
        display: flex;
        width:300% !important;
        justify-content: space-between !important;
    }

     
    
    .contents{
      display: block;
    }
    .mobile-locked{
        display: block;
    }

    .desktop-locked{
        display: none !important;
    }
  }