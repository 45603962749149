.active {
    @apply text-c_FFDE00;
  }
  
.active::before {
    @apply opacity-100;
    transform: scale(1);
  }
  


  @media screen and (max-width:900px) {
    .mobile-link{
      display: block;
    }

    .mobile-link-off{
      display: none;
    }
    .t-desktop{
      opacity: 0 !important;
    }

    .t-mobile{
      display: block;
    }

    .t-mobile .row{
      border-bottom: 1px solid #ffde01;
      padding-block: 20px;
      padding-inline: 10px;
    }
    /* ?\test */

    .row .column-head{
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 6px;
    }

    .row .column-data{
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 13px;
      opacity: 0.8;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }

  }

  @media screen  and (min-width:970px) {
    .t-desktop{
      opacity: 1;
    }
    .t-mobile{
      display: none;
    }
  }