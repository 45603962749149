.wallet_dialog_functions li {
  @apply px-6;
}

.wallet_dialog_functions li:hover {
  @apply bg-c_090AC14D;
}

.wallet_dialog_functions button {
  @apply flex items-center py-5 w-full;
}

.wallet_dialog_functions button span {
  @apply proxima-n-semibold-18 text-c_818181;
}

.wallet_unlock_button {
  @apply justify-between;
}

.unlocks_in {
  @apply flex flex-col items-end;
}

.unlocks_in span {
  @apply text-c_FFDE00 text-sm !important;
}
.unlock_btn {
  @apply flex gap-1 items-center;
}
