.lTable_container {
  @apply relative;
}

.lTable {
  @apply border-collapse w-full bg-transparent table-fixed;
}

.lTableHead {
  @apply bg-main_bg;
  @apply brandon-g-reg-16 text-white;
}

.lTableHead th {
  @apply py-4 font-light lg:text-base text-xs;
}

.lBody {
  @apply bg-c_010127;
}

.lBody td {
  @apply text-center  py-2 border-b-2 border-t-2 border-solid border-c_090AC1;
  @apply border-opacity-20;
  box-shadow: 0px 0px 6px #02024740;
}

.lBody tr:last-child td {
  border-bottom: 0;
}

.linkCol {
  /* width: 100px;
  @apply xl:w-36; */
}

.unlockCol {
  /* width: 120px;
  @apply xl:w-40; */
}

.tRow {
  @apply brandon-g-reg-14 text-white;
}

.tRow td div {
  @apply flex items-center flex-col lg:text-base text-xxs leading-4;
}

.token {
  @apply text-c_FFDE00 cursor-pointer;
}
.symbol {
}

.type {
}

.lockDate {
}

.amount {
}

.lockedValue {
}

.unlocksIn {
}

.lockStatus div {
  align-items: flex-end !important;
  padding-right: 1rem;
}

.lockStatus_manage div {
  align-items: center !important;
}

.lockStatus div p,
.token div p {
  @apply flex gap-4 justify-between items-center lg:text-base text-xxs;
}

.symbol span,
.lockDate span,
.amount span,
.lockDate span {
  @apply brandon-g-reg-13;
}

.lockStatus_locked {
  @apply text-c_11C120;
}

.lockStatus_unlocked {
  @apply text-c_A76727;
}

.lockStatus_withdrawn {
  @apply text-c_F74100;
}
