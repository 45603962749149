.button {
  @apply border border-c_FFDE00 brandon-g-reg-20 rounded-md;
  @apply flex items-center;
  transition: all 0.2s linear 0s;
}

.normal {
  @apply bg-c_FFDE00 text-black;
}

.normal:hover {
  @apply bg-transparent text-c_FFDE00;
}

.outlined {
  @apply bg-transparent text-c_FFDE00;
}

.outlined:hover {
  @apply bg-c_FFDE00 text-black;
}

.button[disabled] {
  @apply bg-c_B9AA46 cursor-not-allowed text-black;
}

.button_loading_img {
  width: 40px;
  height: 24px;
}

.loading:hover {
  @apply bg-current !important;
}
