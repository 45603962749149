.user_locks {
  @apply flex flex-col bg-c_010127 rounded-md;
  min-height: 434px;
}

.user_locks_header {
  @apply w-full flex items-center py-6 px-8;
  @apply border-b border-solid border-c_FFDE0026;
}

.user_locks_header h2 {
  @apply brandon-g-med-17;
}

.user_locks_content {
  @apply w-full;
}

.no_locks {
  @apply flex flex-col items-center justify-center;
  @apply py-16;
}

.no_locks_header {
  @apply brandon-g-med-21 text-white pt-6 pb-3;
}

.no_locks_summary {
  @apply proxima-n-reg-15 text-c_818181 pb-5;
}

.no_locks_button {
  padding: 14px 0;
  @apply flex gap-4 px-8 mt-4;
}

.no_locks_button svg {
  transition: all 0.2s linear 0s;
}

.no_locks_button:hover svg {
  @apply fill-current text-c_FFDE00;
}

.no_locks_button p {
  @apply brandon-g-bold-22;
}

.has_locks {
  @apply flex flex-col items-center;
}
