.lockerSearch {
  @apply absolute w-full py-4 px-7 pl-20 text-xl bg-transparent;
  @apply rounded-md text-white font-thin placeholder-c_FFFFFF4D;
  @apply outline-none border border-opacity-30 transition-all;
  @apply lg:py-5 md:pl-28;
}
.lockerSearchLabel {
  @apply left-0 px-5 py-4 border-r border-opacity-30 transition-all text-white;
  @apply lg:py-5 md:px-8;
}

.lockerSearchLabel svg path {
  @apply transition-all;
}

.lockerSearch,
.lockerSearch + .lockerSearchLabel {
  @apply border-c_090AC169;
}

.lockerSearch:focus + .lockerSearchLabel svg path {
  @apply fill-current;
}

.lockerSearchResult {
  @apply py-6 lg:px-20 bg-c_010127 text-left px-3;
}

.lockerSearchResult img {
  @apply w-dng-50;
}

.lockerSearchResult p {
  @apply lg:text-lg text-base overflow-hidden;
}

.lockerSearchResult:hover {
  @apply bg-c_090AC169;
}
