.wallet_dialog {
  @apply fixed inset-0 z-20 overflow-y-auto;
}

.wallet_dailog_containter {
  @apply min-h-screen px-4 text-center;
}

.wallet_dialog_overlay {
  @apply fixed inset-0 bg-main_bg opacity-80;
}

.wallet_dialog_containter_spacer {
  @apply inline-block h-screen align-middle;
}

.wallet_dialog_content {
  @apply inline-block w-full max-w-lg my-8;
  @apply overflow-hidden text-left align-middle;
  @apply transition-all transform bg-c_010127 shadow-xl rounded-md;
  @apply border border-solid border-c_090AC169;
}

.wallet_dialog_header {
  @apply p-6 flex items-center justify-between bg-c_01011A;
}

.wallet_dialog_close {
  @apply brandon-g-bold-22 text-white cursor-pointer;
  font-size: 36px !important;
  transition: all 0.2s linear 0s;
}

.wallet_dialog_close:hover {
  @apply text-c_FFDE00;
}

.wallet_dialog_title {
  @apply brandon-g-bold-20 text-white;
}

.wallet_dialog_body {
  @apply p-6;
}

.wallet_dialog_notice {
  @apply mt-2 p-4 rounded-md border border-solid;
  border-color: #0c0c30;
  background-color: #0f0f34;
}

.wallet_dialog_notice p {
  @apply proxima-n-reg-12 text-c_E4E4E4;
}

.wallet_dialog_providers {
  @apply mt-4 mb-4;
}

.wallet_dialog_providers_list li {
  @apply px-6;
}

.wallet_dialog_providers_list li:hover {
  @apply bg-c_090AC14D;
}

.wallet_dialog_providers_list button {
  @apply flex items-center py-5 w-full;
}

.wallet_dialog_providers_list button img {
  width: 34px;
}

.wallet_dialog_providers_list button span {
  @apply proxima-n-semibold-22 text-c_818181 ml-6;
}

.wallet_dialog_provider {
  @apply flex items-center justify-between;
}

.wallet_dialog_provider p {
  @apply airfactory-r-reg-22 text-c_FFDE00;
}

.wallet_dialog_disconnect {
  @apply brandon-g-reg-13 px-2 py-1;
}
.wallet_dialog_address {
  @apply mt-4 py-4;
}

.wallet_dialog_address > p {
  @apply proxima-n-reg-21 text-white;
}

.wallet_dialog_address_copy_etherscan {
  @apply flex py-2 gap-6;
}

.wallet_dialog_bottom_text,
.wallet_dialog_bottom_text a {
  @apply relative cursor-pointer flex gap-2 items-center brandon-g-reg-13 text-c_818181;
}

.wallet_dialog_bottom_text img {
  @apply w-4;
}

.tooltip {
  @apply text-left absolute bottom-0 left-0 top-6;
}
