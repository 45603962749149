.manage_lock_button {
  padding: 14px 0;
  @apply flex gap-4 px-8;
}

.manage_lock_button svg {
  transition: all 0.2s linear 0s;
}

.manage_lock_button:hover svg {
  @apply fill-current text-c_FFDE00;
}

.manage_lock_button p {
  @apply brandon-g-bold-22;
}
