@layer base {
  @font-face {
    font-family: "proxima_novaregular";
    src: url("./assets/fonts/proxima-nova/proximanova-regular-webfont.woff2")
        format("woff2"),
      url("./assets/fonts/proxima-nova/proximanova-regular-webfont.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "brandon_grotesquebold";
    src: url("./assets/fonts/brandon-grotesque/brandon_bld-webfont.woff2")
        format("woff2"),
      url("./assets/fonts/brandon-grotesque/brandon_bld-webfont.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "brandon_grotesquemedium";
    src: url("./assets/fonts/brandon-grotesque/brandon_med-webfont.woff2")
        format("woff2"),
      url("./assets/fonts/brandon-grotesque/brandon_med-webfont.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "brandon_grotesqueregular";
    src: url("./assets/fonts/brandon-grotesque/brandon_reg-webfont.woff2")
        format("woff2"),
      url("./assets/fonts/brandon-grotesque/brandon_reg-webfont.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "airfactory_roundedregular";
    src: url("./assets/fonts/airfactory-rounded/airfactory_rounded-webfont.woff2")
        format("woff2"),
      url("./assets/fonts/airfactory-rounded/airfactory_rounded-webfont.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
  }
}

button {
  @apply outline-none;
}

.App {
  text-align: center;
  @apply bg-main_bg w-full;
}

.app__wrapper {
  @apply mx-auto min-h-screen relative;
  @apply 2xl:max-w-screen-xl xl:max-w-screen-lg px-8;
  @apply text-white bg-main_bg;
}

.app__footer {
  @apply fixed bottom-0 left-0 right-0 z-10 bg-main_bg;
}

.dinger_link {
  @apply text-c_FFDE00;
}

.app__content {
  @apply pb-36;
}
