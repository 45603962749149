.search-group {
  width: 100%;
  padding-block: 1.3rem;
  padding-inline: 1.5rem;
  display: flex;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 7px;
  align-items: center;
  @apply bg-c_010127 relative flex-col;
}

.search-group input[type="text"] {
  @apply bg-c_01011A p-4 rounded-md border-c_090AC1 border;
  flex-grow: 1;
  outline: none;
  font-size: 13px;
  color: white;
  font-weight: 400;
}

.search-group img {
  @apply bg-c_01011A;
  position: absolute;
  top: 1px;
  right: 13px;
  bottom: 0px;
  width: 25px;
  height: 25px;
  font-weight: 700;
  display: flex;
  align-items: center;
  transform: translateY(50%);
  margin-top: 0.1rem;
  padding: 0.1rem;
}

.eg {
  @apply text-c_707070 text-xs self-start pt-4;
}
