.lock_details_container {
  @apply grid place-content-center;
}

.lock_details_backdrop {
  max-width: 835px;
  @apply flex items-center justify-center bg-c_010127;
  @apply lg:p-11 p-3 rounded-md;
  @apply lg:w-dng-835 w-full;
  box-shadow: 0 0 6px #02024740;
}

.lock_details {
  @apply flex flex-col bg-c_020232 rounded-md w-full relative;
  min-height: 493px;
  max-width: 749px;
  min-width: 300px;
  box-shadow: 0 0 12px #0101113d;
}

.lock_details_table {
  min-height: 200px;
}

.lock_details_header {
  @apply w-full flex items-center justify-between lg:py-6 lg:px-8 px-4 py-6;
  @apply border-b border-solid border-c_FFDE0026 lg:flex-row flex-col overflow-hidden;
}

.lock_details_symbols {
  @apply flex gap-4 items-center;
}

.lock_details_symbols img {
  height: 30px;
}

.lock_details_symbol {
  @apply flex;
}

.lock_details_title {
  @apply text-white proxima-n-reg-21;
}

.lock_details_links {
  @apply flex items-center lg:gap-8 gap-2 mt-4 lg:mt-0;
}

.lock_details_link {
  @apply flex gap-3 items-center cursor-pointer relative;
}

.lock_details_link img {
  width: 17px;
  height: 17px;
}

.lock_details_link span {
  @apply proxima-n-reg-12 text-c_828282;
}

.tooltip {
  @apply text-left absolute bottom-0 left-0 top-6;
}

.lock_details_content {
  @apply w-full pt-8 md:px-16 px-4 flex flex-col items-center;
}

.lock_details_content_values {
  @apply w-full flex flex-col lg:gap-8 gap-2 justify-center items-center;
}

.lock_details_content_body {
  @apply flex lg:gap-5 gap-1 w-full;
}

.lock_value {
  @apply flex flex-col lg:items-end items-start justify-end flex-1;
  min-width: 30%;
}

.lock_locked {
  @apply flex flex-col items-center justify-end;
  @apply bg-main_bg border rounded border-solid;
  @apply border-c_090AC14D py-4 px-8;
  min-width: 30%;
}

.lock_locked_percentage {
  @apply text-white brandon-g-bold-22 flex gap-4;
  @apply items-center justify-between py-4;
}

.lock_locked_values {
  @apply flex items-center gap-1;
}

.lock_locked_values_l {
  @apply proxima-n-semibold-15 text-c_FFDE00;
}

.lock_locked_values_t {
  @apply proxima-n-reg-14 text-white;
}

.lock_unlock {
  @apply flex flex-col lg:items-start items-end justify-end;
  min-width: 30%;
}

.lock_value_title {
  @apply lg:proxima-n-reg-19 text-c_949494 py-1;
  @apply font-proxima_nova_reg text-xs leading-3;
}

.lock_value_amount {
  @apply lg:proxima-n-semibold-27 text-c_FFDE00 pt-3;
  @apply font-proxima_nova_reg font-semibold text-lg leading-4;
}

.lock_value_token {
  @apply text-c_E4E4E4 font-proxima_nova_reg py-2 lg:text-lg;
  @apply text-sm;
}

.lock_details_footer {
  @apply w-full flex justify-between items-center py-4;
  @apply border-t border-solid border-c_090AC1;
}

.lock_details_footer p {
  @apply text-white lg:proxima-n-reg-18;
  @apply font-proxima_nova_reg text-xs leading-5;
}

.lock_details_loading {
  @apply absolute top-0 bottom-0 left-0 right-0;
  @apply flex flex-col items-center justify-center bg-c_010127 bg-opacity-95;
}

.desktop_lock_locked {
  @apply lg:flex hidden !important;
}

.lock_details_loading p {
  word-break: break-all;
  word-wrap: break-word;
}
